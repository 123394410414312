import React from 'react';
import Navbar from "../header/Navbar";
import SmallBanners from "../SmallBanner/SmallBanners";
import Faqques from '../Faqques/Faq';
import Faqsecond from '../Faqques/Faqsecond';

const Faq = () => {
  return (
    <div>
      <Navbar/>
      <SmallBanners heading="FAQ" text="Understanding the program"/>
      <Faqques/>
      <Faqsecond/>
    </div>
  )
}

export default Faq