import '../section/firstsectionstyles.css';

import checks from '../assets/check.png';
import brokers from '../assets/brokers-imgs.jpg'
import mobimgbrokers from '../assets/section2-mobile.jpg'

import React from 'react';


import {Link} from "react-router-dom";

const FirstSection = () => {
  return (
    <section className='bg-imgs'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 left-items mob-brokers'>
                   <div>
                      <h2 className='headings-broker'>Introducing Brokers</h2>
                   </div>
                   <div className='mob-paras-brok'>
                       <div>
                          <div className='check-imgs'>
                            <img src={checks} alt="checkimg" />
                          </div>
                          <p className='broke-paras'>
                            Multi-tiered, volume-based rebate structures
                          </p>
                       </div>
                       <div>
                          <div className='check-imgs'>
                            <img src={checks} alt="checkimg" />
                          </div>
                          <p className='broke-paras'>
                              Flexible and attractive rebate program 
                          </p>
                       </div>
                       <div>
                          <div className='check-imgs'>
                            <img src={checks} alt="checkimg" />
                          </div>
                          <p className='broke-paras'>
                              Lifetime rebates on clients 
                          </p>
                       </div>
                       <div>
                          <div className='check-imgs'>
                            <img src={checks} alt="checkimg" />
                          </div>
                          <p className='broke-paras'>
                              24/7 advanced real-time tracking technology 
                          </p>
                       </div>
                   </div>
                   <div className='learn-btn'>
                      <span className='red-button'>
                          <Link to="/" className="btn red-btns-text"> LEARN MORE </Link>
                      </span>
                  </div>
                </div>
                <div className='col-md-6'>
                </div>
            </div>
        </div>
    </section>
  );
}

export default FirstSection