import React from 'react';
import Navbar from "../header/Navbar";
import SmallBanners from "../SmallBanner/SmallBanners";
import Introibportal from "../IBPortal/Introibportal";

const Ibportal = () => {
  return (
    <div>
      <Navbar/>
      <SmallBanners heading="The Introducing Broker Portal" text="Advanced IB Analytics & Information Portal"/>
      <Introibportal/>
    </div>
  )
}

export default Ibportal