import '../section/SecondSectionstyle.css';

import checks from '../assets/check.png';
import affilate from '../assets/affiliates-bg.jpg'


import React from 'react';

import {Link} from "react-router-dom";

const SecondSection = () => {
  return (
    <section className='second-bg-img'>
    <div className='container'>
        <div className='row'>
        <div className='col-md-6'>
            </div>
            <div className='col-md-6 right-items mob-affilates'>
               <div>
                  <h2 className='headings-affiliates'>Affiliates</h2>
               </div>
               <div className='mob-affilates'>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="affilateimg" />
                      </div>
                      <p className='affilate-paras'>
                        Multi-tiered, volume-based rebate structures
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='affilate-paras'>
                          Flexible and attractive rebate program 
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='affilate-paras'>
                          Lifetime rebates on clients 
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='affilate-paras'>
                          24/7 advanced real-time tracking technology 
                      </p>
                   </div>
               </div>
               <div className='learn-btn'>
                  <span className='red-button'>
                      <Link to="/" className="btn red-btns-text"> LEARN MORE </Link>
                  </span>
              </div>
            </div>
           
        </div>
    </div>
</section>
  )
}

export default SecondSection