import '../header/Navbarstyle.css';
import React, { useState } from 'react';

import {Link} from "react-router-dom";
import logos from "../assets/logo.png";

import { FaBars, FaTimes } from  "react-icons/fa";


const Navbar = () => {
     const [click, setClick] = useState(false);
     const handleClick = () => setClick (!click);


  return (

    <section className='nav-bg'>
        <div className='container'>
            <div className='row'>
            <div className="col-md-4">
                   </div>
                   <div className="col-md-8">
                        <div className="top-nav mt-4 mob-navs">
                          <ul className="top-navs">
                               <li className="nav-item">
                                    <Link to="/" className="nav-link active">FP Markets Home</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/Whyfpmarkets" className="nav-link">Partners HUB</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/Contact" className="ms-2 affliate-btn">Affiliate Login</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/MarketingTools" className="ms-4 affliate-btn">IB Login</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/MarketingTools" className="nav-link">Contact Us</Link>
                               </li>
                         </ul>
                       </div>
                   </div>
                   <div className="col-md-12 mb-4">
                        <div className="bg-line">
                        </div>
                   </div>
                   <div className= "nav-sections-partners col-md-12 ">
                        <div className='col-md-4'>
                        <Link to="/" class="nav-link active"><img src={logos} alt="logosimg" /></Link>
                        </div>
                         <div className='col-md-8'>
                        <div className='SecondNav'>
                            
                              
                                   
                                   <div>
                                            <ul className={click ? "nav-menu active" : "nav-menu"}>
                                               <li>
                                                    <Link to="/Whyfpmarkets" className="">WHY FP MARKETS</Link>
                                              </li>
                                               <li>
                                                    <Link to="" className="">PARTNERSHIPS</Link>
                                              </li>
                                              <li>
                                                    <Link to="/Ibportal" className="">IB PORTAL</Link>
                                              </li>
                                              <li>
                                                    <Link to="/MarketingTools" className="">MARKETING TOOLS</Link>
                                              </li>
                                              <li>
                                                    <Link to="/Faq" className="">FAQ</Link>
                                              </li>
                                          </ul>
                                        </div>
                                        <div className="hamburger" onClick={handleClick}>
                                       {click ? (
                                          <FaTimes  size={25} style={{color: "#fff"}}/>

                                       ) : ( 

                                       <FaBars size={25} style={{color: "#fff"}}/>

                                      )}
      
                                   </div>
                                  </div> 
                         </div>
                   </div>
            
            </div>
            
        </div>
    </section>
  

  );
}

export default Navbar