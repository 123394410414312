import React from 'react';
import Navbar from "../header/Navbar";
import Firstwhyfpmarket from "../whyfpmarket/Firstwhyfpmarket";
import Whyfpbanners from "../whyfpmarket/whyfpbanner";




const whyfpmarkets = () => {
  return (
    <div>  
      <Navbar/>
      <Whyfpbanners/>
      <Firstwhyfpmarket/>
   
    </div>
  )
}

export default whyfpmarkets



