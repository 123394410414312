import '../whyfpmarket/firstwhyfpmarket.style.css';

import checks from '../assets/check.png';
import ziczac from '../assets/zic-zac-whyfpm.png';
import separtors from '../assets/horizontal-seperator.png'

import React from 'react';
import {Link} from "react-router-dom";




const firstwhyfpmarket = () => {
  return (
    <section className='bg-img-why-fp'>
    <div className='container'>
        <div className='row'>
            <div className='col-md-5 left-items mob-smarts-choice'>
               <div>
                  <h2 className='headings-broker'>The smart <br/>partnership choice</h2>
               </div>
               <div>
                   <div>
                      <p className='broke-paras'>
                         FP Markets is a global broker offering the clients of IBs and Affiliates access to CFDs across Forex, Equities,    Cryptocurrencies, Commodities and Indices. Traders can access over 10,000 trading instruments through a selection of    industry leading platforms including MT4, MT5, IRESS and Webtrader.
                      </p>
                   </div>
                   <div>
                      <p className='broke-paras'>
                      Founded in 2005 and regulated by ASIC, FP Markets has grown to become one of the largest and most reputable players in the industry.
                      </p>
                   </div>
               </div>
            </div>
            <div className='col-md-2 middle-items mob-pics-features' >
                 <img className="separator-middle" src={ziczac} alt="ziczacimg" />
                 <img className="mob-separtor-middle" src={separtors} alt="ziczacimg" />
            </div>
            <div className='col-md-5 right-items partners-progrm-mob'>
            <div>
                  <h2 className='headings-broker'>Partners Program Key Features</h2>
               </div>
               <div className='mob-chechmark'>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Earn up to $800 per referred client
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      High converting rich media advertising material
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Forex, Equities, Cryptocurrencies, Commodities & Indices
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Spreads from 0.0 pips & fast execution
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Real-time commission payments
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Fast client onboarding for higher conversions
                      </p>
                   </div>
                   <div>
                      <div className='check-imgs'>
                        <img src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Multi-tiered customised deals & payout structure
                      </p>
                   </div>
               </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default firstwhyfpmarket







