import '../IBPortal/Introibportalstyle.css';

import checks from '../assets/check.png';
import affilates from '../assets/affiliates-sections.jpg';

import React from 'react';

const Introibportal = () => {
  return (
    <section className='ib-portals-imgs'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 ib-intro-portal'>
                    <h2 className='ib-headings'>Introducing the IB Portal</h2>
                    <p className='ib-paras'>The advanced FP Markets’ IB Portal is designed for the convenience of FP Markets’ Partners. Partners can rely on its transparency and business intelligence to effectively manage their partnership accounts.</p>
                </div>
                <div className='col-md-12'>
                    <div>
                        <h2 className='techy-portal-ib'>Advanced Technology for Analytics & Statistics </h2>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div>
                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                Gain full insight into your referrals activity with enhanced reporting features
                            </p>
                        </div>

                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                View a comprehensive client onboarding journey from deposits, trades and withdrawals
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div>
                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                Gain full insight into your referrals activity with enhanced reporting features
                            </p>
                        </div>
                        
                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                View a comprehensive client onboarding journey from deposits, trades and withdrawals
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div>
                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                Gain full insight into your referrals activity with enhanced reporting features
                            </p>
                        </div>
                        
                        <div className='Advanced-techy-portal'>
                            <div className='techy-imgs-ib'>
                                <img className='check-ib-portal' src={checks} alt="checkimg" />
                            </div>
                            <p className='ib-port-para-techy'>
                                View a comprehensive client onboarding journey from deposits, trades and withdrawals
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Introibportal