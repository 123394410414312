import '../MarketingTools/SecondWebstyle.css';

import checks from '../assets/check.png';
import SmallZicZac from '../assets/seperator-rev.png';

import bannersecond from '../assets/banners.jpg';
import separators from '../assets/seperator.png';

import marketingstoolsmob from '../assets/horizontal-seperator.png';

import React from 'react';
import {Link} from "react-router-dom";

const SecondWebbanners = () => {
  return (
    <section className='bg-img-second-webbaners'>
    <div className='container'>
        <div className='row'>
            <div className='col-md-12 mob-banners-web-sec'>
            <div className='col-md-5 right-items-webbanner'>
            <div>
                  <h2 className='headings-seconds-webbanner'>Website banners </h2>
               </div>
               <div className="marketings-points">
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='webbanner-paras'>
                        FP Markets provides multilingual animated and static banners promoting our USPs
                      </p>
                   </div>
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='webbanner-paras'>
                        Conversion-focused & easily-added to partners websites 
                      </p>
                   </div>
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='webbanner-paras'>
                      Mobile ready to ensure referrals for users on the go
                      </p>
                   </div>
               </div>
            </div>
            
            <div className='col-md-2 middle-items-second-webbanner' >
                 <img className="web-banner-mob" src={separators} alt="separatorsimg" />
                 <img className="mob-separtor-middle" src={marketingstoolsmob} alt="ziczacimg" />
            </div>
            <div className='col-md-5'>
                <img className='banners-imgs-second' src={bannersecond} alt="bannersecondimg" />
            </div>
            </div>
            
            
        </div>
    </div>
</section>
  )
}

export default SecondWebbanners