import './Faqstyle.css';
import separators from '../assets/seperator.png';

import React from 'react';

const Faq = () => {
  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='fp-markets-faq-who'>
              <h2 className='heading-faq-who'>Who is FP Markets?</h2>
            </div>
          </div>
          <div className='col-md-2 mob-fp-arkets-faq'>
            <div className='faq-seaprator-fp'>
                 <img src={separators} alt="separatorsimg" />
            </div>
          </div>
          <div className='col-md-5'>
            <div className='faq-para-sections-fp'>
               <p className='para-for-faqs'>FP Markets is a trusted Global CFD and Forex Broker. We provide execution that is second to none to clients across the globe. We’ve partnered with leading banking and non-banking financial institutions to provide a deep liquidity pool, to offer the best available prices in the market. Spreads start from as low as 0.0 pips and we offer an ever expanding range of instruments on MT4 and MT5 platforms.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  )
}

export default Faq