import React from 'react';
import Navbar from "../header/Navbar";
import SmallBanners from "../SmallBanner/SmallBanners";
import Firstmarketing from "../MarketingTools/Firstmarketing";
import SecondWebbanners from "../MarketingTools/SecondWebbanners";

export const MarketingTools = () => {
  return (
    <div>
        <Navbar/>
        <SmallBanners heading="Marketing Tools" text="High converting rich media banners, landing pages & educational material"/>
        <Firstmarketing/>
        <SecondWebbanners/>
    </div>
  )
}

export default MarketingTools