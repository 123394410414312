import "../HeroImg/HeroImgstyle.css";

import heronavimg from '../assets/new-header.jpg';

import {Link} from "react-router-dom";
// import logos from "../assets/logo.png";

import React from 'react';

export const HeroImg = () => {
  return (
    <section className="heronavImg">
        <div className="container">
            <div className="row">
                   {/* <div className="col-md-4">
                   </div>
                   <div className="col-md-8">
                        <div className="top-nav mt-4">
                          <ul className="top-navs">
                               <li className="nav-item">
                                    <Link to="/" className="nav-link active">FP Markets Home</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/Whyfpmarkets" className="nav-link">Partners HUB</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/Contact" className="ms-2 affliate-btn">Affiliate Login</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/MarketingTools" className="ms-4 affliate-btn">IB Login</Link>
                               </li>
                               <li className="nav-item">
                                    <Link to="/MarketingTools" className="nav-link">Contact Us</Link>
                               </li>
                         </ul>
                       </div>
                   </div>
                   <div className="col-md-12 mb-4">
                        <div className="bg-line">
                        </div>
                   </div>
                   <div className='col-md-4'>
                   <Link to="/" class="nav-link active"><img src={logos} alt="logosimg" /></Link>
                   </div>
                   <div className='col-md-8'>
                        <div className='SecondNav'>
                            <nav class="navbar navbar-expand-lg ">
                                <div class="container-fluid">
                                     <button class="navbar-toggler" type="button" data-bs-toggle="collapse"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                   </button>
                                   <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                               <li class="nav-item">
                                                    <Link to="/Whyfpmarkets" className="">WHY FP MARKETS</Link>
                                              </li>
                                               <li class="nav-item">
                                                    <Link to="/MarketingTools" className="">PARTNERSHIPS</Link>
                                              </li>
                                              <li class="nav-item">
                                                    <Link to="/Contact" className="">IB PORTAL</Link>
                                              </li>
                                              <li class="nav-item">
                                                    <Link to="/MarketingTools" className="">MARKETING TOOLS</Link>
                                              </li>
                                              <li class="nav-item">
                                                    <Link to="/MarketingTools" className="">FAQ</Link>
                                              </li>
                                          </ul>
                                        </div>
                                  </div> 
                             </nav>
                         </div>
                   </div> */}
               <div className="col-md-6">
               </div> 
              <div className="col-md-6 heroitems mob-partners">
                    <div>
                        <h1 className="heading-heroimg mob-partner-heading">FP Markets <br/> Partners Program</h1>
                        <p className="paras-HeroImg mob-para-partners">
                           Maximize your earning potential with one <br/> of the best referral programs in the industry
                        </p>
                    </div>
                    <div className='learn-btn'>
                      <span className='red-button'>
                          <Link to="/" className="btn red-btns-text"> JOIN NOW</Link>
                      </span>
                    </div>


        {/* <div className="hero">
    <div className="mask">
      <img className="into-img" src={heronavimg} alt=""/>
    </div>
    <div className="content">
      <p>HI, I'M A FREELANCER.</p>
      <h1>React Developer.</h1>
      <div>
        <Link to="/Project" className="btn"> Projects</Link>
        <Link to="/Contact" className="btn btn-light"> Contact</Link>
      </div>
    </div>
  </div> */}
        </div>
            </div>
        </div>
        

        
    </section>
  )
}

export default HeroImg