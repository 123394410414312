import React from 'react';
import Navbar from "../header/Navbar";
import Firstsection from "../section/FirstSection";
// import checks from '../assets/check.png';
import SecondSection from "../section/SecondSection"
import HeroImg from "../HeroImg/HeroImg";



const Home = () => {
  return (
    <div>
        <Navbar/>
        <HeroImg/>
        <Firstsection/>
        <SecondSection/>
    </div>
  )
}

export default Home