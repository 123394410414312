import '../Faqques/Faqsecondstyle.css';
import separators from '../assets/seperator.png';

import React from 'react';

const Faqsecond = () => {
  return (
    <section className='faq-second-bg-img'>
       <div className='container'>
       <div className='row'>
        <div className='col-md-5'>
          <div className='fp-markets-faq-who'>
            <h2 className='heading-faq-who'>Why become an FP Markets Partner?</h2>
          </div>
        </div>
        <div className='col-md-2 partners-fp-mob'>
          <div className='faq-seaprator-fp'>
               <img src={separators} alt="separatorsimg" />
          </div>
        </div>
        <div className='col-md-5'>
          <div className='faq-para-sections-fp'>
             <p className='para-for-faqs'>By introducing clients to FP Markets you will share in the profit they generate and receive ongoing payments as your referrals. Clients benefit from some of the best conditions in the market that they tend to stay with us for the long term. Our partners enjoy highly competitive payment structures, comprehensive IB portal and the strength of our reputation in the financial services industry, with FP Markets you are partnering with a business you can trust.</p>
          </div>
        </div>
      </div>
       </div>
  </section>
  )
}

export default Faqsecond