import React from 'react';

import Navbar from './Components/header/Navbar';
import Home from './Components/pages/Home';
import Whyfpmarkets from './Components/pages/Whyfpmarkets';
import Ibportal from './Components/pages/Ibportal'; 
import MarketingTools from './Components/pages/MarketingTools';
import Faq from './Components/pages/Faq';




import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>

      <Routes>
         <Route path="/Navbar" element={<Navbar />} />
         <Route path="/" element={<Home />} />
         <Route path="/Whyfpmarkets" element={<Whyfpmarkets />} />
         <Route path="/Ibportal" element={<Ibportal/>} />
         <Route path="/MarketingTools" element={<MarketingTools />} />
         <Route path="/Faq" element={<Faq />} />
      </Routes>

   </>
  );
}

export default App;

