import "../MarketingTools/Firstmarketingstyle.css"

import checks from '../assets/check.png';
import SmallZicZac from '../assets/seperator-rev.png';

import PointTool from '../assets/points-tools.png';
import marketingstoolsmob from '../assets/horizontal-seperator.png';

import React from 'react';
import {Link} from "react-router-dom";

const Firstmarketing = () => {
  return (
    <section className='bg-img-why-fp'>
    <div className='container'>
        <div className='row'>
            <div className='col-md-5 left-items mob-leads-earn'>
               <div>
                  <h2 className='headings-broker'>Convert your  <br/>leads and earn</h2>
               </div>
               <div>
                   <div>
                      <p className='broke-paras'>
                         FP Markets provides partners with a range of materials to engage new clients and maintain them through the conversion    process.
                      </p>
                   </div>
               </div>
               <div className='points-tools-marketings' >
                   <img src={PointTool} alt="PointToolimg" />
                   {/* <img className="mob-separtor-middle" src={separtors} alt="ziczacimg" /> */}
               </div>
            </div>
            <div className='col-md-2 middle-items-marketing mob-seprator-img' >
                 <img className="small-zic-desk" src={SmallZicZac} alt="SmallZicZacimg" />
                 <img className="mob-separtor-middle" src={marketingstoolsmob} alt="ziczacimg" />
            </div>
            <div className='col-md-5 right-items mob-partners-receive'>
            <div>
                  <h2 className='headings-broker'>FP Markets Partners receive </h2>
               </div>
               <div className="marketings-points">
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Rich media banners, landing pages, educational material
                      </p>
                   </div>
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                        
                      </div>
                      <p className='broke-paras'>
                      Access to a wide range of eye-catching marketing materials to maximise click-through rates 
                      </p>
                   </div>
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Free marketing tools available in our portal where you can track, manage and boost your conversions
                      </p>
                   </div>
                   <div>
                      <div className='markeing-check-imgs'>
                        <img className="marketing-check-imgs" src={checks} alt="checkimg" />
                      </div>
                      <p className='broke-paras'>
                      Regular updates to keep partner websites fresh and informative 
                      </p>
                   </div>
                   
               </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Firstmarketing