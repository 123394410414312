import './whyfpmarketstyle.css';

import React from 'react';

const whyfpbanner = () => {
  return (
    <div className="hero-img">
            <div className="heading">
               <h1>Why FP Markets</h1>
               <p>The premium choice for IBs & Affiliate Marketing experts</p>
            </div>
    </div>
  )
}

export default whyfpbanner